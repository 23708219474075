<template>
  <div class="pb-2">
    <v-card
      elevation="4"
      class="py-2 rounded-lg"
      color="#F8F8F8"
      @click="redirectToDetailVisitor"
    >
      <div class="d-flex">
        <button class="bg-shades-transparent">
          <i class="chevron-left"></i>
        </button>
        <div class="mx-2">
          <p class="my-0 font-weight-bold" v-text="visitor.fullName"></p>
          <p class="my-0" v-text="visitor.companyName"></p>
        </div>
        <div class="d-flex self-align-items ml-auto">
          <v-icon v-if="visitor.sync" color="secondary">mdi mdi-check</v-icon>
          <v-icon v-else class="red--text">mdi mdi-close</v-icon>
          <button class="bg-shades-transparent mx-2">
            <v-icon>mdi mdi-chevron-right</v-icon>
          </button>
        </div>
      </div>
    </v-card>
  </div>
</template>

<style scoped>
.font-size-13 {
  font-size: 13px;
}
</style>

<script>
export default {
  name: "ItemListVisitors",
  props: {
    visitor: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    redirectToDetailVisitor() {
      this.$store.commit("visitors/setTempVisitor", this.visitor);
      if (this.visitor.id) {
        this.$router.push({
          name: "detail-visitor",
          params: { id: this.visitor.id },
        });
      } else {
        this.$router.push({
          name: "visitor",
        });
      }
    },
  },
};
</script>
